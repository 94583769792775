import { gql } from '@apollo/client';
import { getApolloClient } from './apolloClient';
import { LoaderFunctionArgs } from 'react-router-dom';
import { Event, Registration } from './API';
import { mockEvents, mockRegistrations } from './mockData';

const isDev = process.env.NODE_ENV === 'development';

interface EventConnection {
  items: Event[];
  nextToken: string | null;
  __typename: string;
}

interface RegistrationConnection {
  items: Registration[];
  nextToken: string | null;
  __typename: string;
}

// Recursive function to fetch all events
async function fetchAllEventsByEmail(ownerEmail: string, nextToken: string | null = null, accumulated: Event[] = [], limit = 100): Promise<Event[]> {
  try {
    console.log(`Fetching events with ownerEmail: ${ownerEmail}, nextToken: ${nextToken}`);
    const client = await getApolloClient(); // Ensure client is initialized
    const { data } = await client.query<{ listEventsByEmail: EventConnection }>({
      query: gql`
        query ListEventsByEmail($ownerEmail: String!, $nextToken: String, $limit: Int) {
          listEventsByEmail(ownerEmail: $ownerEmail, nextToken: $nextToken, limit: $limit) {
            items {
              eventId
              version
              sourceSiteId
              sourceName
              eventName
              ownerEmail
              eventKey
              ownerFirstName
              ownerLastName
              crmId
              live
              date
              endDate
              startTime
              endTime
              locale
              location
              room
              address
              address2
              city
              state
              zipCode
              description
              calendarStatus
              department
              marketingEffort
              invitationMarketingEffort
              additionalData
              eventRole
              __typename
            }
            nextToken
            __typename
          }
        }
      `,
      variables: { ownerEmail, nextToken, limit },
    });

    console.log('Data received:', data);

    if (data && data.listEventsByEmail) {
      if (data.listEventsByEmail.items) {
        console.log('Fetched events:', data.listEventsByEmail.items);
        accumulated = accumulated.concat(data.listEventsByEmail.items);
        if (data.listEventsByEmail.nextToken) {
          return fetchAllEventsByEmail(ownerEmail, data.listEventsByEmail.nextToken, accumulated, limit);
        } else {
          return accumulated;
        }
      } else {
        console.warn('No items in data.listEventsByEmail:', data.listEventsByEmail);
        return accumulated;
      }
    } else {
      console.warn('Data is not in expected format:', data);
      return accumulated;
    }
  } catch (err) {
    console.error('Error loading events:', err);
    return accumulated;
  }
}

// Event loader
export async function eventLoader() {
  if (isDev) {
    console.log('Using mock events data for development.');
    return mockEvents;
  }

  const ownerEmail = localStorage.getItem('kc_email');
  if (!ownerEmail) {
    console.error('No ownerEmail found in localStorage');
    return [];
  }

  const allEvents = await fetchAllEventsByEmail(ownerEmail);
  console.log('All fetched events:', allEvents);
  return allEvents;
}

// Recursive function to fetch all registrations
async function fetchAllRegistrations(eventId: string, nextToken: string | null = null, accumulated: Registration[] = [], limit = 1000): Promise<Registration[]> {
  try {
    console.log(`Fetching registrations with eventId: ${eventId}, nextToken: ${nextToken}`);
    const client = await getApolloClient(); // Ensures client is initialized
    const { data } = await client.query<{ listRegistrationsByEventId: RegistrationConnection }>({
      query: gql`
        query ListRegistrationsByEventId($eventId: String!, $nextToken: String, $limit: Int) {
          listRegistrationsByEventId(eventId: $eventId, nextToken: $nextToken, limit: $limit) {
            items {
              registrationId
              eventId
              parentEventKey
              email
              crmId
              firstName
              lastName
              registrationStatus
              dateCreated
              qrBase64
              pdfBase64
              pdfUrl
              live
              guests
              additionalData
              submissionId
              checkedInCount
              __typename
            }
            nextToken
            __typename
          }
        }
      `,
      variables: { eventId, nextToken, limit },
    });

    console.log('Data received:', data);

    if (data && data.listRegistrationsByEventId) {
      if (data.listRegistrationsByEventId.items) {
        console.log('Fetched registrations:', data.listRegistrationsByEventId.items);
        accumulated = accumulated.concat(data.listRegistrationsByEventId.items);
        if (data.listRegistrationsByEventId.nextToken) {
          return fetchAllRegistrations(eventId, data.listRegistrationsByEventId.nextToken, accumulated, limit);
        } else {
          return accumulated;
        }
      } else {
        console.warn('No items in data.listRegistrationsByEventId:', data.listRegistrationsByEventId);
        return accumulated;
      }
    } else {
      console.warn('Data is not in expected format:', data);
      return accumulated;
    }
  } catch (err) {
    console.error('Error loading registrations:', err);
    return accumulated;
  }
}

// Registrations loader
export async function registrationsLoader({ params }: LoaderFunctionArgs) {
  if (isDev) {
    console.log('Using mock registrations data for development.');
    return mockRegistrations;
  }

  const eventId = params.eventId;
  if (!eventId) {
    console.error('No eventId found in params');
    return [];
  }

  const allRegistrations = await fetchAllRegistrations(eventId);
  console.log('All fetched registrations:', allRegistrations);
  return allRegistrations;
}