/* eslint-disable */
import { useState, useEffect } from 'react';
import { Event, Registration } from './API';
import AttendeeList from './AttendeeList';
import ScanTicket from './ScanTicket';
import EventQr from './EventQr';
import { FaVideo, FaUserPlus } from "react-icons/fa";
import { useRouteLoaderData, useLoaderData, useParams, Outlet } from 'react-router-dom';
import NavBar from './NavBar';
import AddUser from './AddUserModal';
import { useSubscription } from '@apollo/client';
import { ON_CREATE_REGISTRATION } from './graphql/subscriptions/onCreateRegistration';
import { ON_UPDATE_REGISTRATION } from './graphql/subscriptions/onUpdateRegistration';
import { createRegistrationRecord } from './mutations/processCreateRegistration';  // Import utility function
import './css/Event.css';

function EventItem() {
  const events = useRouteLoaderData("events") as Event[];
  const [registrations, setRegistrations] = useState<Registration[]>(useLoaderData() as Registration[]);
  const { eventId } = useParams();
  const [scanTicket, setScanTicket] = useState<boolean>(false);
  const [viewQr, setViewQr] = useState<boolean>(false);
  const [showSelfRegistration, setShowSelfRegistration] = useState<boolean>(false);
  const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
  const [showAddRegistration, setShowAddRegistration] = useState<boolean>(false);

  // Form fields for the new registration 
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [guests, setGuests] = useState<number>(1);

  // Local state for loading and error
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const event = events.find(i => i.eventId === eventId);
  if (typeof eventId === "undefined" || typeof event === "undefined") return "Not Found";

  const {
    eventName,
    location,
    date,
    description
  } = event;

  const { data: newRegistrationData, error: newRegistrationError } = useSubscription(ON_CREATE_REGISTRATION, {
    variables: { eventId: eventId || "" },
  });

  const { data: updatedRegistrationData, error: updatedRegistrationError } = useSubscription(ON_UPDATE_REGISTRATION, {
    variables: { eventId: eventId || "" },
  });

  useEffect(() => {
    if (newRegistrationError) {
      console.error('Subscription error:', newRegistrationError);
    }

    if (newRegistrationData) {
      console.log('New registration received:', newRegistrationData);
      const newRegistration = newRegistrationData?.onCreateRegistration as Registration;
      setRegistrations(prevRegistrations => [...prevRegistrations, newRegistration]);
    }
  }, [newRegistrationData, newRegistrationError]);

  useEffect(() => {
    if (updatedRegistrationError) {
      console.error('Subscription error:', updatedRegistrationError);
    }

    if (updatedRegistrationData) {
      console.log('Updated registration received:', updatedRegistrationData);
      const updatedRegistration = updatedRegistrationData?.onUpdateRegistration as Registration;
      setRegistrations(prevRegistrations =>
        prevRegistrations.map(reg =>
          reg.registrationId === updatedRegistration.registrationId ? updatedRegistration : reg
        )
      );
    }
  }, [updatedRegistrationData, updatedRegistrationError]);

  if (!eventId || !event) {
    return <div>Not Found</div>;
  }

  function startScanningTicket() {
    setScanTicket(!scanTicket);
  }

  function onScanTicket(result: Registration | null) {
    return result;
  }

  const readableDate = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  const handleAddUserClick = () => {
    setShowAddUserModal(true);
  };

  const handleCloseAddUserModal = () => {
    setShowAddUserModal(false);
  };

  const handleShowAddRegistration = () => {
    setShowAddRegistration(!showAddRegistration);
  };
  // Handle Add Registration using the utility function
  const handleAddRegistration = async (e: React.FormEvent) => {
    e.preventDefault();

    // Start loading and reset error
    setLoading(true);
    setError(null);

    try {
      const newRegistration = await createRegistrationRecord({
        eventId,
        firstName,
        lastName,
        email,
        guests,
        generateQRAndTicket: false,
      });

      // removing this line resolves stupid bug in AttendeeList
      // setRegistrations(prevRegistrations => [...prevRegistrations, newRegistration as unknown as Registration]);

      // Clear form and close the form after submission
      setFirstName('');
      setLastName('');
      setEmail('');
      setGuests(1 );
      // setShowAddRegistration(false);
    } catch (err) {
      setError('Error creating registration');
      console.error('Error creating registration:', err);
    } finally {
      setLoading(false);  // Stop loading after request is done
    }
  };


  return scanTicket ? (
    <>
      <ScanTicket
        eventId={eventId}
        handler={onScanTicket}
        goBack={() => setScanTicket(false)}
        attendees={registrations}
        setAttendees={setRegistrations}
      />
      <Outlet />
    </>
  ) : viewQr ? (
    <>
      <EventQr
        event={event}
        setViewQr={setViewQr}
      />
      <Outlet />
    </>
  )
    : (
      <div className="event-details">
        <div style={{ display: "flex", alignItems: "center" }}>
          <NavBar eventId={eventId} eventName={eventName} />
        </div>
        <h1 className="event-details-header">
          Event Details for
          <br />
          <b>{eventName}</b>
        </h1>
        <address>{location}</address>
        <div className="event-date">{readableDate}</div>
        <p>{description}</p>
        {registrations.length ? (
          <button
            className='event-button'
            onClick={startScanningTicket}
          >
            <FaVideo className="react-icons" /> Scan Tickets
          </button>
        ) : (
          <button className='event-button' disabled>
            <FaVideo className="react-icons" /> Scan Tickets
          </button>
        )}
        {(event.eventRole === "OWNER" || event.eventRole === "COOWNER") && (
          <div>
            <button className="event-button" onClick={handleAddUserClick}>
              <FaUserPlus className="react-icons" /> Add Users To Event Team
            </button>
          </div>
        )}
        {/* <div className='self-registration-container'>
            <p className='self-registration-text'>Does this event allow walk ins?</p>
            <input
              className='self-registration-checkbox'
              type="checkbox"
              checked={showSelfRegistration}
              onChange={handleSelfRegistrationChange}
            />
          </div> */}

        {/* conditionally display Add Registration form */}
        {/* <div>
          <button className='event-button' onClick={handleShowAddRegistration}>
            Add Registration
          </button>
          {showAddRegistration && (
            <form onSubmit={handleAddRegistration} className="add-registration-container">
              <div>
                <label className='add-registration-label' htmlFor="firstName">First Name</label>
                <input
                  id="firstName"
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </div>

              <div>
                <label className='add-registration-label' htmlFor="lastName">Last Name</label>
                <input
                  id="lastName"
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </div>

              <div>
                <label className='add-registration-label' htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>

              <div>
                <label className='add-registration-label' htmlFor="guests">Guests</label>
                <input
                  id="guests"
                  type="number"
                  placeholder="Guests"
                  value={guests}
                  min="1"
                  onChange={(e) => setGuests(Number(e.target.value))}
                  required
                />
              </div>
              <div>
                <button type="submit" disabled={loading}>
                  {loading ? 'Adding...' : 'Add Registration'}
                </button>
              </div>
              {error && <p className="error-text">{error}</p>}
            </form>
          )}
        </div> */}

        {
          showSelfRegistration && (
            <>
              <div className='disclaimer-container'>
                <p>
                  * Only to be used for free events. Do not distribute this QR code or URL prior to the event.
                </p>
              </div>
              <button
                className='event-button'
                onClick={() => setViewQr(true)}
              >
                Self Sign in Site QR
              </button>

              <button
                className='event-button'
                onClick={() => window.open(`http://events-registration-form-poc.s3-website-us-west-2.amazonaws.com/${eventId}`, '_blank')}
              >
                Self Sign in Site URL
              </button>
            </>
          )
        }
        <AttendeeList attendees={registrations} setAttendees={setRegistrations} />
        <Outlet />
        {
          showAddUserModal && (
            <AddUser eventDetails={event} onClose={handleCloseAddUserModal} />
          )
        }
      </div >
    )
}

export default EventItem;