// src/App.tsx
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { eventLoader, registrationsLoader } from './loaders';
import Layout from './Layout';
import EventList from './EventList';
import EventItem from './EventItem';
import TicketLanding from './TicketLanding';
import './css/App.css';
import KeyCloakService from './security/keycloakService';


function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout />,
      loader: eventLoader,
      id: 'events',
      children: [
        { index: true, element: <EventList /> },
        {
          path: ':eventId',
          element: <EventItem />,
          loader: registrationsLoader,
          children: [
            {
              path: ':eventId/:registrationId',
              element: <TicketLanding />
            }
          ]
        }, 
      ]
    }
  ]);
  return KeyCloakService.keycloakInstance.authenticated ? (
    <RouterProvider router={router} />
  ) : (
    <div>Loading...</div>
  );
}

export default App;